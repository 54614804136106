import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { Button, Drawer } from 'src/components/shared';
// import { Notifications } from 'src/components/App';
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
  ListItemText,
  ListItemIcon,
} from '@mui/material';

import GlobalSearch from '../GlobalSearch';

import { logout } from 'src/modules/auth/actions/authActions';
import {
  openCloseSideBar,
  toggleAddContactForm,
  toggleAddSequenceForm,
  toggleCreateEmailForm,
  toggleAddTaskForm,
  toggleNotificationsDrawer,
} from 'src/modules/app/actions/appActions';
import storage from 'src/utils/storageUtils';
//
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PersonIcon from '@mui/icons-material/Person';
import AppsIcon from '@mui/icons-material/Apps';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SendIcon from '@mui/icons-material/Send';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HelpIcon from '@mui/icons-material/Help';
import CalenderEvent from 'src/modules/calanderEvent';
import PageTitle from './PageTitle';
import PinnedBoards from './PinnedBoards';

import { useIntercom } from 'react-use-intercom';

import images from 'src/config/images';

import {
  useNotificationsStore,
  withNotificationsProvider,
} from 'src/modules/app/utils/notificationStore';

import useStyles from './styles';

const AppHeader = ({
  auth: { isAuthenticated, user },
  logout,
  hasLmsAccess,
  hasOutreachAccess,
  showSidebar,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const { notificationCount } = useNotificationsStore();
  const { boot, show } = useIntercom();

  const name = user ? `${user.fname || ''}` : '';

  const [userNameMenuElement, setUserNameMenuElement] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [plusMenu, setPlusMenu] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState({ open: false });

  const { sideBarIsOpen, currentPageHeader } = useSelector((state) => state.app);

  const togglePlusMenu = (e) => {
    if (plusMenu) {
      setPlusMenu(false);
    } else {
      setPlusMenu(e.currentTarget);
    }
  };

  const matchActive = (pathname) => location.pathname.startsWith(pathname);
  const userNameId = 'user-name-menu-id';
  const plusMenuId = 'plus-menu-id';

  const handleUserNameMenuOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setUserNameMenuElement(event.currentTarget);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleMenuClose = () => {
    setUserNameMenuElement(null);
  };

  const onPlusMenuAction = (action) => {
    switch (action) {
      case 'addContact':
        dispatch(toggleAddContactForm());
        break;
      case 'addSequence':
        dispatch(toggleAddSequenceForm());
        break;

      case 'emailTemplate':
        history.push('/templates/email');
        break;

      case 'linkedinTemplate':
        history.push('/templates/linkedin');
        break;

      case 'addTemplate':
        // case 'emailTemplate':
        // case 'linkedinTemplate':
        dispatch(toggleCreateEmailForm(action));
        break;

      case 'addTask':
        dispatch(toggleAddTaskForm());
        break;

      default:
        break;
    }
    setPlusMenu(false);
  };

  // logout user
  const handleLogout = (event) => {
    // setTenantHash();
    handleMenuClose();
    logout(event);
  };

  const setActiveClass = (url) => (matchActive(url) ? 'active' : '');

  let locations = useLocation();
  ``;
  const path = locations.pathname;
  const isDashboard = path === '/dashboard' && false;
  const isBoards = path === '/boards';
  const dispatch = useDispatch();

  const toggleSidebar = () => {
    dispatch(openCloseSideBar());
  };
  const toggleNotifications = () => {
    dispatch(toggleNotificationsDrawer());
  };

  const dateEvents = (
    <Drawer
      title={'Calender Events'}
      open={drawerOpen.open}
      onClose={() => {
        setDrawerOpen({ open: false });
      }}
    >
      <CalenderEvent />
    </Drawer>
  );

  const hasAdminAccess = ['teamlead', 'admin', 'manager']?.includes(
    user?.acl?.accessLevel?.toLowerCase(),
  );

  const authLinks = (
    <Toolbar variant="dense" className={classes.toolbarStyle}>
      {showSidebar ? (
        <Box display="flex" alignItems="center" flex={1}>
          {!isDashboard && (
            <IconButton onClick={toggleSidebar} style={{ outline: 'none' }} size="medium">
              {sideBarIsOpen ? (
                <FormatIndentDecreaseIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
              ) : (
                <FormatIndentIncreaseIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
              )}
            </IconButton>
          )}
          <PageTitle />
        </Box>
      ) : (
        <img src={images.app.logo} style={{ height: '30px' }} />
      )}

      <Box flex={1}>
        <GlobalSearch />
      </Box>

      <Box component="div" display="flex" alignItems="center" flex={1} justifyContent="flex-end">
        {showSidebar && (
          <>
            <Box>
              <Tooltip title="Help & Support">
                <Button
                  iconButton
                  onClick={async () => {
                    try {
                      await boot();
                      await show();
                    } catch (error) {}
                  }}
                  size="medium"
                >
                  <HelpIcon />
                </Button>
              </Tooltip>

              {/*<PinnedBoards />*/}

              <Button
                iconButton
                onClick={(e) => {
                  setDrawerOpen({ open: true });
                }}
                className={classes.plusMenu}
                size="medium"
              >
                <CalendarMonthIcon />
              </Button>
            </Box>
            {dateEvents}

            <Button
              iconButton
              onClick={(e) => {
                togglePlusMenu(e);
              }}
              className={classes.plusMenu}
              size="medium"
            >
              <AddCircleOutlineIcon />
            </Button>

            <Button
              iconButton
              onClick={(e) => {
                // history.push('/outreachvideolibrary');
                history.push('/onboarding');

              }}
              size="medium"
            >
              <RocketLaunchIcon />
            </Button>

            {/* <Button iconButton onClick={toggleNotifications} size="medium">
              <NotificationsNoneOutlinedIcon />
            </Button> */}
            {/*<Notifications />*/}
          </>
        )}

        <Menu
          anchorEl={plusMenu}
          id="plus-menu-icon"
          keepMounted
          open={Boolean(plusMenu)}
          onClose={togglePlusMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{
            paper: classes.menuPaper,
          }}
        >
          <MenuItem onClick={() => onPlusMenuAction('addContact')}>
            <ListItemIcon>
              <PersonAddIcon />
            </ListItemIcon>
            <Typography>Contact</Typography>
          </MenuItem>
          <MenuItem onClick={() => onPlusMenuAction('addSequence')}>
            <ListItemIcon>
              <SendIcon />
            </ListItemIcon>
            <Typography>Sequence</Typography>
          </MenuItem>

          <MenuItem onClick={() => onPlusMenuAction('addTask')}>
            <ListItemIcon>
              <CheckCircleOutlineIcon />
            </ListItemIcon>
            <Typography>Task</Typography>
          </MenuItem>
          <MenuItem onClick={() => onPlusMenuAction('emailTemplate')}>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <Typography>Email Template</Typography>
          </MenuItem>
          <MenuItem onClick={() => onPlusMenuAction('linkedinTemplate')}>
            <ListItemIcon>
              <LinkedInIcon />
            </ListItemIcon>
            <Typography>LinkedIn Template</Typography>
          </MenuItem>
        </Menu>

        <Tooltip title={name || ''} aria-label="add">
          <Button
            onClick={handleUserNameMenuOpen}
            endIcon={<ExpandMoreIcon />}
            className={classes.userButton}
          >
            <Typography
              className={`${classes.typography} ${classes.userName}`}
              aria-controls={userNameId}
              aria-haspopup="true"
              component="div"
            >
              {name || ''}
            </Typography>
          </Button>
        </Tooltip>

        <Menu
          anchorEl={userNameMenuElement}
          id={userNameId}
          keepMounted
          open={Boolean(userNameMenuElement)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          classes={{
            paper: classes.menuPaper,
          }}
        >
          {hasOutreachAccess && (
            <MenuItem
              onClick={() => {
                if (!hasOutreachAccess) {
                  window.location.replace(process.env.REACT_APP_LMS_URL);
                } else {
                  history.push('/sequences');
                }
                handleMenuClose();
              }}
            >
              <ListItemIcon>
                <AppsIcon fontSize="small" />
              </ListItemIcon>
              <Typography>SuperReach</Typography>
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              history.push('/profile/details');
              handleMenuClose();
            }}
          >
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <Typography>Profile</Typography>
          </MenuItem>
          {hasAdminAccess && (
            <MenuItem
              to={'/admin/users'}
              onClick={() => {
                const route = history.location.pathname;
                if (route && route !== '') {
                  storage.set('REFERRAL_ROUTE', route);
                }
                window.open('/admin/users', '_blank');
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Administration" />
            </MenuItem>
          )}
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <PowerSettingsNewIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem>
        </Menu>
      </Box>
    </Toolbar>
  );

  return path.includes('sequence/steps') ? null : isAuthenticated ? (
    <AppBar
      className={`${
        !showSidebar
          ? classes.noSidebar
          : isDashboard
          ? classes.rootDashboard
          : isBoards
          ? classes.rootBoards
          : classes.root
      } ${!sideBarIsOpen ? classes.rootCollapsed : ''}`}
      color="inherit"
      position="fixed"
    >
      {authLinks}
    </AppBar>
  ) : null;
};

AppHeader.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(AppHeader);
