import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  Backdrop,
  Stack,
  Select,
  Dialog,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Form } from 'src/components/shared';
import * as Yup from 'yup';

import SeqTypeTab from './SeqTypeTab';
import { useStyles } from './styles';
import images from 'src/config/images';
import AIPromptView from './AIPromptView';
import { useSelector } from 'react-redux';
import { fetchBrandVoice } from '../../../api/sequenceApi';
import toast from '../../../../../utils/toast';
import { useSocketStore, withSocketStore } from '../../../../../components/hooks/socketConnector';
import { fetchAiPromptForm } from '../../../../app/api/appApis';

const AIButton = ({ classes, label, step, onClick, loading }) => {
  return (
    <Button
      className={classes.aiButton}
      form="ai-writer-form"
      loading={loading}
      type={'submit'}
      onClick={onClick}
    >
      <img src={images.icons.magicIcon} /> {label}
    </Button>
  );
};

const AIInsertButton = ({ classes, label, step, onClick, disabled }) => {
  return (
    <Button className={classes.aiInsertButton} disabled={disabled} onClick={onClick}>
      <img src={images.icons.checkIcon} /> {label}
    </Button>
  );
};

const AIWriterFormHeader = ({
  options,
  classes,
  step,
  showSelect,
  selectedEmailFormType,
  setSelectedEmailFormType,
  ...props
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={{ padding: '16px 24px' }}
      >
        <Stack direction={'row'} alignItems={'center'} gap={step === 0 ? '24px' : '16px'}>
          {step === 1 ? (
            <img
              src={images.icons.backIcon}
              onClick={() => props?.setStep(0)}
              style={{ cursor: 'pointer' }}
              alt={''}
            />
          ) : null}
          <Typography className={classes.headerText}>
            {step === 0 ? 'AI Writer' : 'Back to AI Prompt'}
          </Typography>
          {step === 0 && props?.formType === 'email' ? (
            <Select
              variant={'outlined'}
              fullWidth
              sx={{
                width: '204px',
                color: '#1976D2',
                background: '#1976D214',
                '& .MuiOutlinedInput-input': {
                  padding: '6px 4px 6px 16px',
                  fontSize: '14px',
                },
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: 0,
                },
              }}
              isSmall
              value={selectedEmailFormType}
              onChange={(e) => {
                setSelectedEmailFormType(e?.target?.value);
              }}
              options={options}
            >
              {options.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          ) : null}
        </Stack>
        <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => props?.handleClose()} />
      </Stack>
    </Box>
  );
};

function AIWriteForm(props) {
  if (!props?.open) return <></>;
  const { emit, aiResponse: socketAiResponse } = useSocketStore();

  const classes = useStyles();
  const [step, setStep] = React.useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [aiResponse, setAIResponse] = useState([]);
  const [aiPromptFormResponse, setAiPromptFormResponse] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedEmailFormType, setSelectedEmailFormType] = useState('');
  const [formValues, setFormValues] = useState({});
  const [regeneratedValueIndex, setRegeneratedValueIndex] = useState(null);
  const aiFormFieldsWithValue = useSelector((state) => state.app.globals?.aiFormFieldsWithValue);

  const isEmailForm = props?.formType === 'email';
  const linkedInFormFields = [
    {
      fieldName: 'type',
      label: 'Type',
      fieldType: 'radio',
      fieldOptions: ['Client', 'Candidate', 'Other'],
    },
    {
      fieldName: 'purpose',
      label: 'Purpose',
      fieldType: 'lookup',
      prompt: 'Please specify the purpose of the email.',
      fieldChildren: aiFormFieldsWithValue?.Purpose || [],
    },
    {
      fieldName: 'toneOfVoice',
      label: 'Tonality',
      fieldType: 'lookup',
    },
    {
      fieldName: 'furtherInstruction',
      label: 'Further Instructions',
      fieldType: 'textArea',
    },
  ];

  const fetchAiPromptFormData = async () => {
    setLoadingPage(true);
    try {
      const aiPromptFormData = await fetchAiPromptForm();
      if (aiPromptFormData.length) {
        setSelectedEmailFormType(aiPromptFormData[0].formName);
        setAiPromptFormResponse(aiPromptFormData);
      }
      setLoadingPage(false);
    } catch (error) {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    if (isEmailForm) {
      fetchAiPromptFormData();
    }
  }, []);

  useEffect(() => {
    if (regeneratedValueIndex) {
      aiResponse[regeneratedValueIndex] = socketAiResponse;
      setAIResponse([...aiResponse]);
    } else {
      setAIResponse([socketAiResponse]);
    }
  }, [socketAiResponse]);

  const getWriteFromHeaderOptions = useMemo(() => {
    return aiPromptFormResponse.map((item) => item.formName);
  }, [aiPromptFormResponse]);

  const getCurrentForm = useMemo(() => {
    if (isEmailForm) {
      return aiPromptFormResponse.find((item) => item.formName === selectedEmailFormType);
    } else {
      return { formFields: linkedInFormFields };
    }
  }, [selectedEmailFormType, aiPromptFormResponse, isEmailForm]);

  useEffect(() => {
    getCurrentForm?.formFields.forEach((item) => {
      if (item.fieldName.includes('type')) {
        setFormValues({ ...formValues, [item.fieldName]: item.fieldOptions[0] });
      }
    });
  }, [getCurrentForm]);

  const getInitialValues = () => {
    return getCurrentForm?.formFields.reduce((initialValues, item) => {
      return {
        ...initialValues,
        [item.fieldName]: formValues?.[item.fieldName] || '',
      };
    }, {});
  };

  const getFormValidation = () => {
    if (!getCurrentForm?.formFields) {
      return {};
    }
    return getCurrentForm.formFields.reduce((validation, item) => {
      if (item.fieldName.includes('type')) {
        return {
          ...validation,
          [item.fieldName]: Yup.string().required(`${item.label} is required`),
        };
      }
      if (item.fieldName.includes('purpose')) {
        return {
          ...validation,
          [item.fieldName]: Yup.string().when(isEmailForm ? 'email_type' : 'type', {
            is: (type) => {
              return !isEmailForm;
            },
            then: Yup.string().required('Please select purpose'),
            otherwise: Yup.string(),
          }),
        };
      }
      return validation;
    }, {});
  };

  const aiType = useCallback(
    (option) => {
      if (option.length) {
        return option.map((type) => {
          return {
            label: type,
            value: type,
          };
        });
      }
      return [];
    },
    [aiFormFieldsWithValue?.Type],
  );

  const aiTonality = useMemo(() => {
    if (aiFormFieldsWithValue?.Tonality) {
      return aiFormFieldsWithValue?.Tonality.map((type) => {
        return {
          label: type,
          value: type,
        };
      });
    }
    return [];
  }, [aiFormFieldsWithValue?.Tonality]);

  const getPurposeOptions = (values, data, key) => {
    if (data?.length) {
      return data
        ?.find((item) => item?.optionValue === values?.[key])
        ?.fields?.map((item) => ({ label: item, value: item }));
    }
    return [];
  };

  const handleClose = () => {
    setStep(0);
    setSelectedOption(null);
    props?.setOpenAIWriter(false);
  };

  const aiGenerationType = {
    email: 'Email',
    linkedin: 'Linkedin Messages',
    linkedinMail: 'Linkedin Inmail',
    linkedinConnection: 'LinkedIn Connection',
  };

  const generateAIContent = async (values) => {
    setLoading(true);
    setFormValues({ ...values });
    const finalValue =
      isEmailForm && selectedEmailFormType !== 'Client - CV Spec'
        ? {
            type: values?.email_type,
            purpose: values?.email_purpose,
            furtherInstruction: values?.further_instructions,
          }
        : { ...values };
    console.log('@@@finalValue@@', finalValue);
    if (values?.tone_of_voice?.name && isEmailForm) {
      finalValue.toneOfVoice = [values?.tone_of_voice?.name];
    } else if (values?.toneOfVoice?.name && !isEmailForm) {
      finalValue.toneOfVoice = [values?.toneOfVoice?.name];
    } else {
      finalValue.toneOfVoice = [];
    }
    finalValue.aiGeneratingType = aiGenerationType[props?.formType];
    try {
      emit(
        'pipeline:execute',
        JSON.stringify({
          slug: isEmailForm ? 'create-email' : 'create-linkedin-message',
          payload: { ...finalValue },
        }),
      );
      setStep(1);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } catch (e) {
      toast.error('AI generation failed', 'tc');
    }
  };

  const regenerateContent = async (index) => {
    setRegeneratedValueIndex(index);
    setLoading(true);
    const finalValue = { ...formValues };
    if (formValues?.toneOfVoice?.name) {
      finalValue.toneOfVoice = [formValues?.toneOfVoice?.name];
    }
    finalValue.aiGeneratingType = aiGenerationType[props?.formType];
    emit(
      'pipeline:execute',
      JSON.stringify({
        slug: isEmailForm ? 'create-email' : 'create-linkedin-message',
        payload: { ...finalValue },
      }),
    );
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  const insertSelectedTemplate = () => {
    props?.setSelectedAiResponse(aiResponse[selectedOption]);
    props?.setOpenAIWriter(false);
  };

  return (
    <>
      <Dialog
        sx={{
          maxWidth: '900px',
          width: '100%',
          left: { xs: '3%', lg: '23%' },
        }}
        PaperProps={{
          sx: {
            borderRadius: '15px',
            maxWidth: '900px',
            width: '100%',
          },
        }}
        maxWidth="md"
        open={props?.open}
        disableEscapeKeyDown
        hideBackdrop
        onClose={handleClose}
      >
        {props?.loading ||
          (loadingPage && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={props?.loading || loadingPage}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          ))}
        <Box sx={{ overflowY: 'auto' }}>
          <Box>
            <AIWriterFormHeader
              options={getWriteFromHeaderOptions}
              handleClose={handleClose}
              step={step}
              showSelect={props?.showSelect}
              setStep={setStep}
              classes={classes}
              selectedEmailFormType={selectedEmailFormType}
              setSelectedEmailFormType={setSelectedEmailFormType}
              {...props}
            />
            <Box className={classes.cardContent}>
              {step === 0 ? (
                <Form
                  initialValues={getInitialValues()}
                  validationSchema={Yup.object().shape(getFormValidation())}
                  enableReinitialize={true}
                  onSubmit={generateAIContent}
                >
                  {({ values, ...formProps }) => {
                    return (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          formProps.submitForm();
                          return false;
                        }}
                        id="ai-writer-form"
                        style={{ minHeight: '365px', height: '100%', width: '100%' }}
                        noValidate
                      >
                        <Box
                          display="flex"
                          flex={1}
                          flexDirection="column"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          height="100%"
                        >
                          <Box width="100%">
                            <Grid container sx={{ marginTop: '16px' }}>
                              {getCurrentForm?.formFields.map((item) => {
                                if (item.fieldType === 'text') {
                                  return (
                                    <>
                                      <Grid item xs={2} container alignItems="center">
                                        <Typography className={classes.label}>
                                          {item.label}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={10}>
                                        <Form.Field.Input
                                          fullWidth
                                          variant="outlined"
                                          name={item.fieldName}
                                          label={item.label}
                                          style={{ marginBottom: '0px' }}
                                          size="small"
                                          isSmall
                                        />
                                      </Grid>
                                    </>
                                  );
                                }
                                if (item.fieldType === 'radio') {
                                  const findChildField = getCurrentForm.formFields.find(
                                    (ele) =>
                                      ele.fieldName !== item.fieldName &&
                                      item.fieldOptions.every(
                                        (val, inx) =>
                                          val === ele?.fieldChildren?.[inx]?.optionValue,
                                      ),
                                  );
                                  return (
                                    <>
                                      <Grid item xs={2} container alignItems="center">
                                        <Typography className={classes.label}>
                                          {item.label}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={10}>
                                        <SeqTypeTab
                                          value={values?.[item.fieldName]}
                                          onClick={async (value) => {
                                            await formProps?.setFieldValue(item.fieldName, value);
                                            await formProps?.setFieldValue(
                                              findChildField?.fieldName,
                                              '',
                                            );
                                          }}
                                          formProps={formProps}
                                          seqTypeList={aiType(item.fieldOptions)}
                                          error={formProps?.errors?.type}
                                        />
                                      </Grid>
                                    </>
                                  );
                                }
                                if (item.fieldType === 'lookup' && item?.fieldChildren?.length) {
                                  const findOptionField = getCurrentForm.formFields.find(
                                    (ele) =>
                                      ele.fieldName !== item.fieldName &&
                                      item.fieldChildren.every(
                                        (val, inx) => val.optionValue === ele?.fieldOptions?.[inx],
                                      ),
                                  );
                                  return (
                                    <>
                                      <Grid item xs={2} container alignItems="center">
                                        <Typography className={classes.label}>
                                          {item.label}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={10}>
                                        <Form.Field.Select
                                          disabled={!values?.[findOptionField.fieldName]}
                                          options={getPurposeOptions(
                                            values,
                                            item.fieldChildren,
                                            findOptionField.fieldName,
                                          )}
                                          placeholder={'Please select'}
                                          fullWidth
                                          size="small"
                                          variant="outlined"
                                          name={item.fieldName}
                                          // label="Category"
                                          optLabel="label"
                                          isSmall
                                          optValue="value"
                                        />
                                      </Grid>
                                    </>
                                  );
                                }
                                if (
                                  item.fieldType === 'lookup' &&
                                  (item.fieldName === 'tone_of_voice' ||
                                    item.fieldName === 'toneOfVoice')
                                ) {
                                  return (
                                    <>
                                      <Grid item xs={2} container alignItems="center">
                                        <Typography className={classes.label}>
                                          {item.label}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={10}>
                                        {/*<Form.Field.AutoComplete*/}
                                        {/*  multiple={false}*/}
                                        {/*  fullWidth*/}
                                        {/*  options={[]}*/}
                                        {/*  variant="outlined"*/}
                                        {/*  remoteMethod={(search) => {*/}
                                        {/*    return fetchTimezoneLookup(search);*/}
                                        {/*  }}*/}
                                        {/*  name="timezone"*/}
                                        {/*  label="Timezone"*/}
                                        {/*  optLabel="name"*/}
                                        {/*  optValue="id"*/}
                                        {/*  tip="Change timezone for all selected users."*/}
                                        {/*/>*/}

                                        <Form.Field.AutoComplete
                                          // options={aiTonality}
                                          options={[]}
                                          fullWidth
                                          variant="outlined"
                                          name={item.fieldName}
                                          placeholder={'Please select'}
                                          // label="Please select"
                                          optValue="id"
                                          optLabel="name"
                                          // isSmall
                                          remoteMethod={(val) => fetchBrandVoice(val)}
                                          size={'small'}
                                          InputLabelProps={{
                                            sx: {
                                              fontSize: '14px',
                                            },
                                          }}
                                        />
                                      </Grid>
                                    </>
                                  );
                                }
                                if (item.fieldType === 'textArea') {
                                  return (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        container
                                        alignItems="center"
                                        sx={{ mt: '11px' }}
                                      >
                                        <Typography className={classes.label}>
                                          {item.label}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Form.Field.Input
                                          fullWidth
                                          multiline
                                          rows={14}
                                          variant="outlined"
                                          name={item.fieldName}
                                          style={{ marginBottom: '0px', marginTop: '8px' }}
                                          placeholder={
                                            isEmailForm
                                              ? 'Add in any additional instructions that will help create the email. The more information provided the  more accurate the response. Consider your audience, what specific information or message you want to convey, what objective you are trying to achieve.!'
                                              : 'Add in any additional instructions that will help create the email.Example can include:  What specific information or message do you want to convey to convey?'
                                          }
                                          size="small"
                                          isSmall
                                        />
                                      </Grid>
                                    </>
                                  );
                                }
                              })}
                            </Grid>
                          </Box>
                        </Box>
                      </form>
                    );
                  }}
                </Form>
              ) : null}
              {step === 1 ? (
                <AIPromptView
                  aiResponse={aiResponse}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  classes={classes}
                  refetchContent={regenerateContent}
                  loading={loading}
                />
              ) : null}
            </Box>
            <Box sx={{ width: '100%' }}>
              <Box className={classes.cardFooter}>
                {step === 0 ? (
                  <AIButton
                    classes={classes}
                    loading={loading}
                    step={step}
                    label={'Generate email'}
                  />
                ) : null}
                {step === 1 ? (
                  <AIInsertButton
                    onClick={() => insertSelectedTemplate()}
                    classes={classes}
                    step={step}
                    disabled={!selectedOption?.toString()}
                    label={'Insert'}
                  />
                ) : null}
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default withSocketStore(AIWriteForm);
