import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

const TitleWrap = styled.div`
  & .appear-item {
    display: none;
  }
  &:hover .appear-item {
    display: block;
  }
  display: 'none';
`;

function PageTitle() {
  const [title, setTitle] = useState('');
  const pathname = useSelector((state) => state.router.location.pathname);

  useEffect(() => {
    switch (true) {
      case pathname === '/boards':
        setTitle('Dashboards');
        break;

      case pathname.includes('task'):
        setTitle('Tasks');
        break;

      case pathname.includes('/profile/'):
        setTitle('My Profile');
        break;

      case pathname.includes('/voice/update'):
        setTitle('Tone of voice');
        break;

      case pathname === '/voice/update/:id':
        setTitle('Tone of voice');
        break;

      case pathname === '/tasks/inbox':
      case pathname.includes('/tasks/inbox/'):
        setTitle('Due');
        break;

      case pathname === '/tasks/outbox':
      case pathname.includes('/tasks/outbox/'):
        setTitle('Upcoming');
        break;

      case pathname === '/tasks/errors':
      case pathname.includes('/task/errors/'):
        setTitle('Errors');
        break;

      case pathname === '/contacts':
        setTitle('People');
        break;

      case pathname.includes('/contact/'):
        setTitle('Contact Details');
        break;

      case pathname === '/sequences':
        setTitle('Sequences');
        break;

      case pathname === '/inbox':
        setTitle('Inbox');
        break;

      case pathname === '/sent':
        setTitle('Sent');
        break;

      case pathname === '/outbox':
        setTitle('Scheduled');
        break;

      case pathname === '/admin/notes':
        setTitle('Add a Note');
        break;
      // case pathname === '/admin/competitions':
      //   setTitle('Add a Competition');
      //   break;

      case pathname === '/admin/course/create':
        setTitle('Create a Course');
        break;

      case pathname.includes('/sequence/'):
        setTitle('Sequence details');
        break;

      case pathname === '/dashboard':
        setTitle('Dashboard');
        break;

      case pathname === '/admin/teams':
        setTitle('Teams');
        break;

      case pathname === '/admin/offices':
        setTitle('Offices');
        break;

      case pathname === '/templates':
      case pathname === '/templates/email':
        setTitle('Email Templates');
        break;

      case pathname === '/templates/linkedin':
      case pathname === '/admin/linkedin-template':
        setTitle('LinkedIn templates');
        break;

      case pathname === '/admin/custom-fields':
        setTitle('Manage fields');
        break;

      case pathname === '/admin/users':
        setTitle('Users');
        break;

      case pathname === '/admin/notes':
        setTitle('Notes');
        break;

      case pathname === '/admin/resellers/automation':
        setTitle('Resellers automation');
        break;

      case pathname === '/admin/resellers/dashboard' || pathname === '/admin/resellers/referral':
        setTitle('Resellers dashboard');
        break;

      case pathname === '/admin/integrations':
        setTitle('Integrations');
        break;

      case pathname === '/admin/settings':
        setTitle('Settings');
        break;

      case pathname === '/admin/data/waterfall':
        setTitle('Data Waterfall');
        break;

      // case pathname.includes('/admin/billing'):
      //   setTitle('Billing Settings');
      //   break;

      case pathname.includes('/admin/roleplay'):
        setTitle('Roleplay Settings');
        break;

      // case pathname.includes('/admin/roleplay-minutes'):
      //   setTitle('Role-play Minutes');
      //   break;

      default:
        setTitle('');
        break;
    }
  }, [pathname]);

  return (
    <TitleWrap>
      <Typography variant="h2">{title}</Typography>
      {(pathname === '/admin/templates' || pathname === '/admin/linkedin-template') && (
        <Typography variant="h6" className="appear-item">
          {'Set up and manage templates for all users, to help increase their efficiency.'}
        </Typography>
      )}
    </TitleWrap>
  );
}

export default PageTitle;
